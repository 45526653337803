import React from "react";
import EXIF from "exif-js";

//------------------------------------------------------------------------------------
export const recorrerObjetoJsonLoop = (objeto) => {
  let tab = 0;

  let multiploEspacio = 50;

  let Campos = [];
  recorrerObjetoJson(objeto, tab);
  function recorrerObjetoJson(objeto, tab) {
    let TabPadre = tab;
    for (let i in objeto) {
      if (objeto.hasOwnProperty(i)) {
        let val = objeto[i];
        if (typeof val == "object") {
          Campos.push(
            <div
              style={{ paddingLeft: tab * multiploEspacio, textAlign: "left" }}
            >
              {i}
            </div>
          );
          tab++;
          recorrerObjetoJson(val, tab);
          tab--;
        } else {
          Campos.push(
            <div
              style={{ paddingLeft: tab * multiploEspacio, textAlign: "left" }}
            >
              {" "}
              {i}:{val}
            </div>
          );
        }
      }
    }
    return Campos;
  }
  return Campos;
};

//------------------------------------------------------------------------------------
export const fechaMesDia = (fecha) => {
  let hoy;
  if (fecha == 0) {
    hoy = new Date();
  } else {
    hoy = new Date(fecha);
  }
  let dd = hoy.getDate();
  let mm = hoy.getMonth() + 1; //hoy es 0!
  let yyyy = hoy.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  hoy = dd + "/" + mm;
  return hoy;
};

//------------------------------------------------------------------------------------
export const calcularEdad = (fechaNacimiento, fechaEvento, edadActual) => {
  if (fechaNacimiento === undefined) {
    return 0;
  }

  // Si la fecha es correcta, calculamos la edad
  let values = fechaNacimiento.split("-");

  if (values[2] === undefined) {
    values = fechaNacimiento.split("/");
  }
  let dia = values[2];
  let mes = values[1];
  let ano = values[0];

  let fecha_hoy = fechaEvento.split("-");

  // si edad actual el true se toma la fecha del evento.
  let ahora_dia = fecha_hoy[2];
  let ahora_mes = fecha_hoy[1];
  let ahora_ano = fecha_hoy[0];
  if (!edadActual) {
    // tomamos el 31 de diciembre del a;o del evento
    ahora_dia = 31;
    ahora_mes = 12;
    ahora_ano = fecha_hoy[0];
  }

  // realizamos el calculo
  let edad = ahora_ano - ano;
  if (ahora_mes < mes) {
    edad--;
  }
  if (mes == ahora_mes && ahora_dia < dia) {
    edad--;
  }
  if (edad > 1900) {
    edad -= 1900;
  }

  return edad;
};
//------------------------------------------------------------------------------------
export const getCleanedString = (cadena) => {
  // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
  cadena = cadena.replace(/Á/gi, "A");
  cadena = cadena.replace(/É/gi, "E");
  cadena = cadena.replace(/Í/gi, "I");
  cadena = cadena.replace(/Ó/gi, "O");
  cadena = cadena.replace(/Ú/gi, "U");
  cadena = cadena.replace(/Ñ/gi, "N");
  return cadena;
};

//------------------------------------------------------------------------------------
export const formatEventDate = (date) => {
  let split = date.split("-");
  return split[2] + "-" + split[1] + "-" + split[0];
};

//------------------------------------------------------------------------------------
export const fechaSeparada = (fecha) => {
  if (fecha == undefined) {
    return 0;
  }

  // Si la fecha es correcta, calculamos la edad
  let values = fecha.split("-");

  if (values[2] == undefined) {
    values = fecha.split("/");
  }
  let dia = values[2];
  let mes = values[1];
  let ano = values[0];

  return { dia: dia, mes: mes, ano, ano };
};
//------------------------------------------------------------------------------------
export const modalidadCateogoriaNameByCode = (
  ModalidadesEventoSnap,
  codigoModalidad,
  codigoCategoria,
  Genero
) => {
  /*
    let modalidad = ModalidadesEventoSnap.child("modalidad_"+codigoModalidad+"/descripcionModalidad/").val();
    let categoria = ModalidadesEventoSnap.child("modalidad_"+codigoModalidad+"/ramaCat/rama_"+
      Genero+"/categorias/categoria_"+codigoCategoria+"/nombreCategoria/").val();
*/
  let modalidad = ModalidadesEventoSnap["modalidad_" + codigoModalidad];
  let modalidadDesc =
    modalidad != undefined ? modalidad["descripcionModalidad"] : undefined;
  let Rama =
    modalidad != undefined ? modalidad.ramaCat["rama_" + Genero] : undefined;
  let categoria =
    Rama != undefined
      ? Rama.categorias["categoria_" + codigoCategoria]
      : undefined;
  let categoriaNombre =
    categoria != undefined ? categoria.nombreCategoria : undefined;

  let modalidadCategoria = {
    modalidad: modalidadDesc,
    categoria: categoriaNombre,
  };
  return modalidadCategoria;
};
//------------------------------------------------------------------------------------
export const completeNull = (value) => {
  if (value == undefined) {
    return "pendiente";
  } else if (value === true) {
    return "si";
  } else if (value === false) {
    return "no";
  } else if (typeof value === "string") {
    return value.replace(/,|;/gi, "");
  } else {
    return value;
  }
};

//------------------------------------------------------------------------------------
export const fechahora = (timestamp) => {
  let hoy;
  if (fecha == 0) {
    hoy = new Date();
  } else {
    hoy = new Date(timestamp * 1000);
  }

  let mes = hoy.getMonth() + 1;

  let dd = hoy.getDate() < 10 ? "0" + hoy.getDate() : hoy.getDate();
  let mm = mes < 10 ? "0" + mes : mes;
  let yyyy = hoy.getFullYear();

  let h = hoy.getHours() < 10 ? "0" + hoy.getHours() : hoy.getHours();
  let m = hoy.getMinutes() < 10 ? "0" + hoy.getMinutes() : hoy.getMinutes();
  let s = hoy.getSeconds() < 10 ? "0" + hoy.getSeconds() : hoy.getSeconds();

  hoy = yyyy + "/" + mm + "/" + dd + " " + h + ":" + m + ":" + s;
  return hoy;
};
export const fechahoraNew = () => {
  var hoy;
  hoy = new Date();

  var mes = hoy.getMonth() + 1;

  var dd = hoy.getDate() < 10 ? "0" + hoy.getDate() : hoy.getDate();
  var mm = mes < 10 ? "0" + mes : mes;
  var yyyy = hoy.getFullYear();

  var h = hoy.getHours() < 10 ? "0" + hoy.getHours() : hoy.getHours();
  var m = hoy.getMinutes() < 10 ? "0" + hoy.getMinutes() : hoy.getMinutes();
  var s = hoy.getSeconds() < 10 ? "0" + hoy.getSeconds() : hoy.getSeconds();

  hoy = yyyy + "/" + mm + "/" + dd + " " + h + ":" + m + ":" + s;
  return hoy;
};
//------------------------------------------------------------------------------------
export const segundosATiempo = (Segundos) => {
  let hours = Math.floor(Segundos / 3600);
  let minutes = Math.floor((Segundos % 3600) / 60);
  let seconds = Math.floor(Segundos % 60);

  //Anteponiendo un 0 a los horas si son menos de 10
  hours = hours < 10 ? "0" + hours : hours;

  //Anteponiendo un 0 a los minutos si son menos de 10
  minutes = minutes < 10 ? "0" + minutes : minutes;

  //Anteponiendo un 0 a los segundos si son menos de 10
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds; // 2:41:30
};
//------------------------------------------------------------------------------------
export const tiempoASegundos = (Tiempo) => {
  let divTime = Tiempo.split(":");
  let H = divTime[0] / 1;
  let M = divTime[1] / 1;
  let S = divTime[2] / 1;

  return H * 60 * 60 + M * 60 + S;
};
//------------------------------------------------------------------------------------
export const RestarTiempos = (Tiempo1, Tiempo2) => {
  // restar tiempos, el tiempo2 es mayor que el tiempo 1
  console.log(Tiempo1);
  console.log(Tiempo2);

  let divTime = Tiempo1.split(":");
  let H1 = divTime[0] / 1;
  let M1 = divTime[1] / 1;
  let S1 = divTime[2] / 1;
  let Segundos1 = H1 * 60 * 60 + M1 * 60 + S1;

  let divTime2 = Tiempo2.split(":");
  let H2 = divTime2[0] / 1;
  let M2 = divTime2[1] / 1;
  let S2 = divTime2[2] / 1;
  let Segundos2 = H2 * 60 * 60 + M2 * 60 + S2;

  let SegundosFinal = Segundos2 - Segundos1;

  let hours = Math.floor(SegundosFinal / 3600);
  let minutes = Math.floor((SegundosFinal % 3600) / 60);
  let seconds = Math.floor(SegundosFinal % 60);

  //Anteponiendo un 0 a los minutos si son menos de 10
  minutes = minutes < 10 ? "0" + minutes : minutes;

  //Anteponiendo un 0 a los segundos si son menos de 10
  seconds = seconds < 10 ? "0" + seconds : seconds;

  let result = hours + ":" + minutes + ":" + seconds; // 2:41:30

  return result;
};
//------------------------------------------------------------------------------------

export const operarTiempos = (tiempo1, tiempo2, operacion) => {
  const [h1, m1, s1] = tiempo1.split(":").map(Number);
  const [h2, m2, s2] = tiempo2.split(":").map(Number);
  console.log("TiempoNew1", tiempo1);
  console.log("TiempoNew2", tiempo2);

  let totalSegundos1 = h1 * 3600 + m1 * 60 + s1;
  let totalSegundos2 = h2 * 3600 + m2 * 60 + s2;

  let resultadoSegundos =
    operacion === "sumar"
      ? totalSegundos1 + totalSegundos2
      : totalSegundos1 - totalSegundos2;

  // Asegurar que no haya valores negativos
  resultadoSegundos = Math.max(0, resultadoSegundos);

  const horas = Math.floor(resultadoSegundos / 3600);
  const minutos = Math.floor((resultadoSegundos % 3600) / 60);
  const segundos = resultadoSegundos % 60;

  const tiempo = [horas, minutos, segundos]
    .map((unit) => unit.toString().padStart(2, "0"))
    .join(":");

  console.log("TiempoNew3", tiempo2);

  return tiempo;
};
//------------------------------------------------------------------------------------

export const TiempoAPasoMinxK = (Tiempo, Distancia) => {
  let divTime = Tiempo.split(":");
  let H = divTime[0] / 1;
  let M = divTime[1] / 1;
  let S = divTime[2] / 1;

  let Segundos = (H * 60 * 60 + M * 60 + S) / Distancia;

  let minutes = Math.floor(Segundos / 60);
  let seconds = Math.floor(Segundos % 60);

  //Anteponiendo un 0 a los minutos si son menos de 10
  minutes = minutes < 10 ? "0" + minutes : minutes;

  //Anteponiendo un 0 a los segundos si son menos de 10
  seconds = seconds < 10 ? "0" + seconds : seconds;

  let result = minutes + ":" + seconds; // 161:30

  return result;
};
//------------------------------------------------------------------------------------
export const TiempoAPasoBy = (Tiempo, Distancia, type) => {
  let divTime = Tiempo.split(":");
  let H = divTime[0] / 1;
  let M = divTime[1] / 1;
  let S = divTime[2] / 1;

  if (type === "min/100mts") {
    let Segundos = (H * 60 * 60 + M * 60 + S) / (Distancia * 10);

    let minutes = Math.floor(Segundos / 60);
    let seconds = Math.floor(Segundos % 60);

    //Anteponiendo un 0 a los minutos si son menos de 10
    minutes = minutes < 10 ? "0" + minutes : minutes;

    //Anteponiendo un 0 a los segundos si son menos de 10
    seconds = seconds < 10 ? "0" + seconds : seconds;
    return minutes + ":" + seconds + " min/100mts"; // 161:30
  } else if (type === "km/h") {
    let kmporHora = Distancia / ((H * 60 * 60 + M * 60 + S) / 3600);

    return Math.floor(kmporHora * 100) / 100 + " km/h"; // 161:30
  }

  let Segundos = (H * 60 * 60 + M * 60 + S) / Distancia;

  let minutes = Math.floor(Segundos / 60);
  let seconds = Math.floor(Segundos % 60);

  //Anteponiendo un 0 a los minutos si son menos de 10
  minutes = minutes < 10 ? "0" + minutes : minutes;

  //Anteponiendo un 0 a los segundos si son menos de 10
  seconds = seconds < 10 ? "0" + seconds : seconds;

  let result = minutes + ":" + seconds + " min/k"; // 161:30

  return result;
};

export const timestampToDate = (timestamp) => {
  let fecha = new Date(timestamp);
  let seg =
    fecha.getSeconds() < 10 ? "0" + fecha.getSeconds() : fecha.getSeconds();
  let min =
    fecha.getMinutes() < 10 ? "0" + fecha.getMinutes() : fecha.getMinutes();
  let horas = fecha.getHours() < 10 ? "0" + fecha.getHours() : fecha.getHours();
  let dia = fecha.getDate() < 10 ? "0" + fecha.getDate() : fecha.getDate();
  console.log(dia);
  let mes =
    fecha.getMonth() + 1 < 10
      ? "0" + (fecha.getMonth() + 1)
      : fecha.getMonth() + 1;
  let year = fecha.getFullYear();
  let fechaFinal =
    year + "/" + mes + "/" + dia + " " + horas + ":" + min + ":" + seg;

  return fechaFinal;
};
// ##########################################################################################
export const sumarSegAFecha = (fechaParam, segundosParam) => {
  let fechaSplit = fechaParam.split(" ");
  let fechaTomada = new Date(fechaSplit[0] + "T" + fechaSplit[1]);
  let fecha = new Date(
    fechaTomada.setTime(fechaTomada.getTime() + segundosParam * 1000)
  );

  let seg =
    fecha.getSeconds() < 10 ? "0" + fecha.getSeconds() : fecha.getSeconds();
  let min =
    fecha.getMinutes() < 10 ? "0" + fecha.getMinutes() : fecha.getMinutes();
  let horas = fecha.getHours() < 10 ? "0" + fecha.getHours() : fecha.getHours();
  let dia = fecha.getDate() < 10 ? "0" + fecha.getDate() : fecha.getDate();
  console.log(dia);
  let mes =
    fecha.getMonth() + 1 < 10
      ? "0" + (fecha.getMonth() + 1)
      : fecha.getMonth() + 1;
  let year = fecha.getFullYear();
  let fechaFinal =
    year + "-" + mes + "-" + dia + " " + horas + ":" + min + ":" + seg;

  return fechaFinal;
};
// ##########################################################################################
export const fecha = (fecha) => {
  let hoy;
  if (fecha === 0) {
    hoy = new Date(); //hora
  } else {
    hoy = new Date(fecha);
  }
  let dd = hoy.getDate();
  let mm = hoy.getMonth() + 1; //hoy es 0!
  let yyyy = hoy.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return yyyy + "-" + mm + "-" + dd;
};
// ##########################################################################################
export const fechaDiagonal = (fecha) => {
  let hoy;
  if (fecha === 0) {
    hoy = new Date(); //hora
  } else {
    hoy = new Date(fecha);
  }
  let dd = hoy.getDate();
  let mm = hoy.getMonth() + 1; //hoy es 0!
  let yyyy = hoy.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return yyyy + "/" + mm + "/" + dd;
};
// ##########################################################################################
export const formatfechaDDMMYYYFromYYYYMMDD = (fecha) => {
  fecha.split("-");
  return fecha[2] + "-" + fecha[1] + "-" + fecha[0];
};
// ##########################################################################################

export const restarFechas = (fechaInicio, fechaFin) => {
  if (
    fechaFin.localeCompare("") === 0 ||
    fechaFin.localeCompare("0000-00-00 00:00:00") === 0 ||
    fechaInicio.localeCompare("") === 0 ||
    fechaInicio.localeCompare("0000-00-00 00:00:00") === 0
  ) {
    return "00:00:00";
  } else {
    var Inicio = new Date(fechaInicio);
    var Fin = new Date(fechaFin);

    var InicioSeg = Inicio.getTime() / 1000;
    var FinSeg = Fin.getTime() / 1000;

    var SegundosFinal = FinSeg - InicioSeg;

    var hours = Math.floor(SegundosFinal / 3600);
    var minutes = Math.floor((SegundosFinal % 3600) / 60);
    var seconds = Math.floor(SegundosFinal % 60);

    //Anteponiendo un 0 a lass horas si son menos de 10
    hours = hours < 10 ? "0" + hours : hours;

    //Anteponiendo un 0 a los minutos si son menos de 10
    minutes = minutes < 10 ? "0" + minutes : minutes;

    //Anteponiendo un 0 a los segundos si son menos de 10
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds; // 2:41:30
  }
};

export const sortJSON = (data, key, way) => {
  return data.sort((a, b) => {
    let x = a[key];
    let y = b[key];
    if (way === "123") {
      return x < y ? -1 : x > y ? 1 : 0;
    }
    if (way === "321") {
      return x > y ? -1 : x < y ? 1 : 0;
    }
    return true;
  });
};

// funcion de ordenamiento
export const helperMultisort = (arr, columns, order_by) => {
  if (typeof columns === "undefined") {
    columns = [];
    for (let x = 0; x < arr[0].length; x++) {
      columns.push(x);
    }
  }

  if (typeof order_by === "undefined") {
    order_by = [];
    for (let y = 0; y < arr[0].length; y++) {
      order_by.push("ASC");
    }
  }

  function multisort_recursive(a, b, columns, order_by, index) {
    let direction = order_by[index] === "DESC" ? 1 : 0;

    let is_numeric = !isNaN(+a[columns[index]] - +b[columns[index]]);

    let x = is_numeric ? +a[columns[index]] : a[columns[index]]; //.toLowerCase();
    let y = is_numeric ? +b[columns[index]] : b[columns[index]]; //.toLowerCase();

    if (x < y) {
      return direction === 0 ? -1 : 1;
    }

    if (x === y) {
      return columns.length - 1 > index
        ? multisort_recursive(a, b, columns, order_by, index + 1)
        : 0;
    }

    return direction === 0 ? 1 : -1;
  }

  return arr.sort(function (a, b) {
    return multisort_recursive(a, b, columns, order_by, 0);
  });
};
// ##########################################################################################
// ################################## UTILS PARA FOTOS ##########################################
// ##########################################################################################
// ##########################################################################################
export function getPhotoFileToUpload(file, size, photoType, currentEvent) {
  if (!file) {
    return false;
  }
  return new Promise((resolve, reject) => {
    console.log(file.name);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (evt) {
      let fechaTomada = "";
      let orientacion = "";
      EXIF.getData(file, function () {
        fechaTomada = EXIF.getTag(this, "DateTime");
        orientacion = 1; //EXIF.getTag(this, "Orientation");
        console.log(orientacion);

        let img = new Image();
        img.src = reader.result;
        img.onload = function () {
          resolve(
            resizeImageToUploadAndRotate(
              img,
              size,
              orientacion,
              photoType,
              currentEvent
            )
          );
        };
      });
    };
    reader.onerror = function (evt) {
      reject(false);
    };
  });
}

// ##########################################################################
export function makeid(largo) {
  let text = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < largo; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

// ##########################################################################
function resizeImageToUploadAndRotate(
  img,
  maxsize,
  srcOrientation,
  photoType,
  currentEvent
) {
  let width = img.width,
    height = img.height,
    canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d");

  let MAX_WIDTH = maxsize;
  let MAX_HEIGHT = maxsize;

  // set proper canvas dimensions before transform & export
  if ([5, 6, 7, 8].indexOf(srcOrientation) > -1) {
    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = height;
    canvas.height = width;
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
    canvas.width = width;
    canvas.height = height;
  }
  // set proper canvas dimensions before transform & export

  // transform context before drawing image
  switch (srcOrientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, width, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, width, height);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, height);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      ctx.transform(0, 1, -1, 0, height, 0);
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, height, width);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, width);
      break;
    default:
      ctx.transform(1, 0, 0, 1, 0, 0);
  }

  ctx.drawImage(img, 0, 0, width, height);

  //let dato = canvas.toDataURL("image/jpeg");
  //dato = dato.replace("image/jpeg", "image/octet-stream");
  //document.location.href = dato;

  //   let logos = [ {
  //     "anchoLogo" : 0.3,
  //     "codLogo" : 2,
  //     "descripcion" : "Logo Dashport",
  //     "margen" : 30,
  //     "posicion" : 4,
  //     "rutaLogo" : "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_white_horizontal.png?alt=media&token=af4ac181-a489-4716-94d6-9c0aca71ff73"
  //   } ]
  //   insertaLogos (logos);

  let blob = dataURItoBlob(canvas.toDataURL("image/jpeg"));
  return blob;
}
// ###################################################
function dataURItoBlob(dataURI) {
  let byteString = atob(dataURI.split(",")[1]);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
}
// ###################################################

//##################################################################
function insertaLogos(logos) {
  for (let i in logos) {
    let logo = logos[i].rutaLogo;
    let anchoLogoPorcentaje = logos[i].anchoLogo;
    let margen = logos[i].margen;
    let posicionMarca = logos[i].posicion;
    this.insertaLogo(logo, anchoLogoPorcentaje, margen, posicionMarca);
  }
}

//##################################################################
function insertaLogo(logo, anchoLogoPorcentaje, margen, posicionMarca) {
  // creo la imagen con la ruta recibida
  let img = new Image();
  img.crossOrigin = "";
  img.src = logo;

  // descargo del servidor y calculo ancho y alto.
  img.onload = () => {
    let anchoLogo = img.width;
    let altoLogo = img.height;

    // ajusto tamano en relacion al ancho especificado que ocupara en la foto
    let anchoLogoFinal = this.anchoFoto * anchoLogoPorcentaje;
    let altoLogoFinal = (anchoLogoFinal * altoLogo) / anchoLogo;

    let posX = margen;
    let posY = margen;

    switch (posicionMarca) {
      case 2: // arriba a la derecha
        posX = this.anchoFoto - anchoLogoFinal - margen;
        break;
      case 3: // abajo a la izquierda
        posY = this.altoFoto - altoLogoFinal - margen;
        break;
      case 4: // abajo a la derecha
        posX = this.anchoFoto - anchoLogoFinal - margen;
        posY = this.altoFoto - altoLogoFinal - margen;
        break;
    } // 1 o 0 arriba a la izquierda

    // dibujo la imagen sobre la foto original
    this.ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      posX,
      posY,
      anchoLogoFinal,
      altoLogoFinal
    );
  };
}
//##################################################################
function insertaMarco(marco) {
  console.log("marco click" + marco);
  //marco = 'KmetasV4/VistasV4/Configuracion/Imagenes/LogosKmetas/MarcoCTriatlonMerida.png';
  // creo la imagen con la ruta recibida
  let img = new Image();
  img.crossOrigin = "";
  img.src = marco;

  // descargo del servidor y calculo ancho y alto.
  img.onload = () => {
    // dibujo la imagen sobre la foto original
    this.ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      0,
      0,
      this.anchoFoto,
      this.altoFoto
    );
    this.insertaLogos();
  };
}
//##################################################################
