import { Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { TiempoAPasoMinxK } from "../../../../services/utils";
import { DescargarCertificadoReg } from "./certificadoLogic";

const descargarCertificadoReg = (
  e,
  eventDetail,
  resultadoJSON,
  tipo,
  certificateConfig
) => {
  e.preventDefault();
  console.log("descargar certificado");
  DescargarCertificadoReg(eventDetail, resultadoJSON, tipo, certificateConfig);
};

export default function AtleteData({ eventDetail, resultado }) {
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  var paso = TiempoAPasoMinxK(resultado.to, resultado.mod);

  const certificateConfig =
    eventConfiguration.timingConfig &&
    eventConfiguration.timingConfig.certificateConfig
      ? eventConfiguration.timingConfig.certificateConfig
      : false;
  function setStatus(resultadoFunc, eventConfigurationFunc) {
    resultadoFunc.text = "";
    resultadoFunc.button = "";
    if (
      resultadoFunc.nombre.indexOf("*") > 0 &&
      eventConfigurationFunc &&
      eventConfigurationFunc.timingConfig &&
      eventConfigurationFunc.timingConfig.showAll
    ) {
      resultadoFunc.pG = 0;
      resultadoFunc.pR = 0;
      resultadoFunc.pCat = 0;
      resultadoFunc.dnfPlus = true;
    }
    if (resultadoFunc.borrado) {
      resultadoFunc.dq = true;
    }
    if (!resultadoFunc.borrado && resultadoFunc.pG === 0) {
      resultadoFunc.dnf = true;
    }
    if (
      !resultadoFunc.registroSalida &&
      !resultadoFunc.parciales &&
      resultadoFunc.pG === 0 &&
      eventConfigurationFunc &&
      eventConfigurationFunc.timingConfig &&
      eventConfigurationFunc.timingConfig.showDNS
    ) {
      resultadoFunc.dns = true;
    }
    if (resultadoFunc.dnfPlus && !resultadoFunc.dns) {
      resultadoFunc.text =
        "El atleta no finalizó el evento en el tiempo previsto para la distancia  ";
      resultadoFunc.button = "DNF";
    } else if (resultadoFunc.dnf && !resultadoFunc.dns) {
      resultadoFunc.text = "El atleta no finalizó el evento  ";
      resultadoFunc.button = "DNF";
    } else if (resultadoFunc.dq) {
      resultadoFunc.text =
        "El atleta fué descalificado por los jueces del evento";
      resultadoFunc.button = "DQ";
    } else if (resultadoFunc.dns && !resultadoFunc.dq) {
      resultadoFunc.text = "El atleta no inició el evento";
      resultadoFunc.button = "DNS";
    }

    return resultadoFunc;
  }

  resultado = { ...resultado, ...setStatus(resultado, eventConfiguration) };
  console.log("resultado1", resultado);
  return (
    <div>
      <div className="row">
        <div
          style={{ padding: 15, marginTop: 20 }}
          className="col s12 m8 left-align"
        >
          <Typography variant={"h3"}>{resultado.nombre}</Typography>
          <Typography variant={"subtitle"}>{resultado.text}</Typography>
        </div>
        {resultado.pG > 0 && !resultado.borrado && (
          <div className="col s12 m4 center-align">
            <Typography style={{ padding: 0 }} variant={"subtitle1"}>
              Certificado
            </Typography>
            <br />

            <a
              onClick={(e) =>
                descargarCertificadoReg(
                  e,
                  eventDetail,
                  resultado,
                  2,
                  certificateConfig
                )
              }
              className="waves-effect waves-teal btn-flat red white-text"
              target="_blank"
            >
              JPG
            </a>
            <a
              onClick={(e) =>
                descargarCertificadoReg(
                  e,
                  eventDetail,
                  resultado,
                  1,
                  certificateConfig
                )
              }
              className="waves-effect waves-teal btn-flat red white-text"
              target="_blank"
            >
              PDF
            </a>
          </div>
        )}
      </div>
      <div className="divider"></div>
      <div className="row">
        <span className="col s6 m3 center-align">
          <b>Número </b>
          <br />
          <Typography variant={"h5"}>{resultado.num}</Typography>

          <span></span>
        </span>
        <span className="col s6 m3 center-align">
          <b>Género </b>
          <br />
          <Typography variant={"h5"}>{resultado.gen}</Typography>
        </span>
        <span className="col s6 m3 center-align">
          <b>Categoría </b>
          <br />
          <Typography variant={"h5"}>{resultado.cat}</Typography>
        </span>
        <span className="col s6 m3 center-align">
          <b>Distancia </b>
          <br />
          <Typography variant={"h5"}>{resultado.mod}</Typography>
        </span>
      </div>
      <div className="row z-depth-2">
        <div
          style={{ padding: 10 }}
          className="#f44336 red white-text col s6 m4 l2 center-align"
        >
          <b>T. Oficial</b>
          <br />
          <Typography variant={"h4"}>{resultado.to}</Typography>
        </div>
        <div
          style={{ padding: 10 }}
          className="#f44336 red white-text col s6 m4 l2 center-align"
        >
          <b>T. Real</b>
          <br />
          <Typography variant={"h4"}>{resultado.tc}</Typography>
        </div>
        <div
          style={{ padding: 10 }}
          className="#f44336 red white-text col s6 m4 l2 center-align"
        >
          <b>Paso min/K</b>
          <br />
          <Typography variant={"h4"}>{paso}</Typography>
        </div>
        <div
          style={{ padding: 10 }}
          className="#f44336 red white-text col s6 m4 l2 center-align"
        >
          <b>P. General</b>
          <br />
          <Typography variant={"h4"}>
            {resultado.pG > 0 ? resultado.pG : " - "}
          </Typography>
        </div>
        <div
          style={{ padding: 10 }}
          className="#f44336 red white-text col s6 m4 l2 center-align"
        >
          <b>P. Rama</b>
          <br />
          <Typography variant={"h4"}>
            {resultado.pR > 0 ? resultado.pR : " - "}
          </Typography>
        </div>
        <div
          style={{ padding: 10 }}
          className="#f44336 red white-text col s6 m4 l2 center-align"
        >
          <b>P. Categoria</b>
          <br />
          <Typography variant={"h4"}>
            {resultado.pCat > 0 ? resultado.pCat : " - "}
          </Typography>
        </div>
      </div>
    </div>
  );
}
