import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import PersonIcon from "@material-ui/icons/Person";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EventIcon from "@material-ui/icons/Event";
//import UserPaymentMethodsAdd from "../UserProfile/UserPaymentMethodsAdd";
import CardPaymentList from "./CardPaymentList";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Conekta from "../../../../../conekta/ConektaHelper";
import { showSnackbar } from "../../../../../data-store/actions/organizers-actions";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";

import { useTracking } from "react-tracking";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CardPayment(props) {
  const {
    callback,
    order,
    test,
    currency,
    currencyBase,
    currentOrder,
    eventDetail,
  } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const registryParticipantKey = useSelector(
    (state) => state.registry.registryParticipantKey
  );

  const { trackEvent } = useTracking();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [sending, setSending] = useState(false);
  // const [cardNumber, setCardNumber] = useState(null);
  // const [cardOwner, setCardOwner] = useState(null);

  function onSubmit(data) {
    // setCardNumber(data.cardNumber);
    // setCardOwner(data.cardOwner);
    setSending(true);
    // console.log("data", data);
    // console.log("data", cardNumber);
    // console.log("data", sending);

    tokenizar(data);
  }

  function tokenizar(data) {
    const cardNumber = data.cardNumber;
    const cardOwner = data.cardOwner;

    function successCallback(token) {
      console.log("success", cardOwner);
      console.log(token);
      var amount = 0;
      var items = order;
      var conektaItems = [];
      for (var i in items) {
        var price = items[i].unit_price;
        price =
          Math.round(parseFloat(price * currencyBase.cambioAPeso) * 100) / 100;
        console.log("payment", items);

        var item = {
          name: items[i].name,
          quantity: items[i].quantity,
          unit_price: Math.round(price * 100),
        };

        amount =
          Math.round(
            (parseFloat(amount) +
              parseFloat(price) * parseFloat(items[i].quantity)) *
              100
          ) / 100;
        console.log("amount " + amount);
        conektaItems.push(item);
      }

      var last4 = cardNumber.substring(
        cardNumber.length - 4,
        cardNumber.length
      );

      var jsonDatos = {
        correo: currentOrder.correo,
        token: token.id,
        nombres: cardOwner,
        telefono: currentOrder.telefono,
        tipoPago: "card",
        inscritoKey: registryParticipantKey,
        eventoIndex: "evento_" + eventDetail.iDEvento,
        last4: last4,
        brand: "",
        amount: amount,
        jsonItems: conektaItems,
      };
      console.log("payment", jsonDatos);

      console.log(jsonDatos);

      var headers = {
        headers: { "Access-Control-Allow-Origin": true },
      };

      axios
        .post(
          "https://us-central1-kmetasfirebase.cloudfunctions.net/conektaCargoV2",
          jsonDatos,
          headers
        )
        .then((res) => {
          console.log(res);
          callback(true, false);
          dispatch(showSnackbar(true, "Pago exitoso ", 4000));

          const tkl = query.get("tkl");

          trackEvent({
            eventName: "evento_" + eventDetail.iDEvento,
            typeEvent: "Purchase",
            params: {
              label: "Purchase",
              tkl: tkl || "direct",
              order: order,
              eventName: eventDetail.nombreEvento,
              facebookPixel: eventDetail.facebookPixel || false,
              gaId: eventDetail.gaId || false,
            },
          });

          setSending(false);
        })
        .catch((error) => {
          console.log(error);
          setSending(false);
          var errorTexto = "";
          if (error.responseJSON !== undefined) {
            for (var i in error.responseJSON.details) {
              errorTexto =
                errorTexto + error.responseJSON.details[i].message + " - ";
            }
          }
          //callback(false);
          dispatch(
            showSnackbar(true, "Error procesando el pago: " + errorTexto, 2000)
          );
        });
    }

    function errorCallback(err) {
      // err keys: object, type, message, message_to_purchaser, param, code
      console.log("error tokenizando " + JSON.stringify(err));
      console.log(err);
      dispatch(showSnackbar(true, "Error:" + err.message_to_purchaser, 2000));

      //TODO: mostrar aqui error al tokenizar, detalle de numero o datos
      setSending(false);
    }

    var validarConekta = true;

    let production = true;

    if (test) {
      production = false;
    }
    Conekta.initConekta(production);

    const expirationMonth = data.month / 1;
    const expirationYear = data.year / 1;
    console.log(expirationMonth);
    console.log(expirationYear);

    if (!Conekta.validateCardNumber(data.cardNumber)) {
      validarConekta = false;

      dispatch(showSnackbar(true, "Revisa el numero de tarjeta!", 4000));
    }
    if (!Conekta.validateCvc(data.securityCode)) {
      validarConekta = false;
      dispatch(showSnackbar(true, "Revisa el código de seguidad!", 4000));
    }
    if (!Conekta.validateExpirationDate(expirationMonth, expirationYear)) {
      validarConekta = false;
      dispatch(showSnackbar(true, "Revisa la fecha de expiración!", 4000));
    }

    console.log("validar conekta " + validarConekta);

    if (validarConekta) {
      Conekta.tokenize(
        data.cardNumber,
        data.cardOwner,
        expirationMonth,
        expirationYear,
        data.securityCode,
        successCallback,
        errorCallback
      );
    } else {
      setSending(false);
    }
  }

  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        style={{ padding: "10px", textAlign: "center" }}
      >
        <Typography color="primary" component="h1" variant="h3">
          Tarjeta de crédito o débito
        </Typography>
        <Typography color="primary" component="h1" variant="h6">
          Ingresa los datos para continuar
        </Typography>
      </Grid>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          alignItems={"left"}
          style={{ margin: "40px" }}
        >
          <Typography color="primary" variant="h5">
            Nombre del propietario
          </Typography>
          <TextField
            style={{ width: "100%" }}
            helperText={errors.cardOwner ? "Campo requerido" : null}
            InputProps={{
              style: {
                marginBottom: 15,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon style={{}} />
                </InputAdornment>
              ),
            }}
            defaultValue={currentOrder.nombres + " " + currentOrder.apellidos}
            error={Boolean(errors.cardOwner)}
            fullWidth
            inputRef={register({ required: true })}
            name="cardOwner"
          />
          <Typography color="primary" variant="h5">
            Número de tarjeta
          </Typography>
          <TextField
            style={{ width: "100%" }}
            helperText={errors.cardNumber ? "Campo requerido" : null}
            InputProps={{
              style: {
                marginBottom: 15,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <CreditCardIcon style={{}} />
                </InputAdornment>
              ),
            }}
            error={Boolean(errors.cardNumber)}
            fullWidth
            inputRef={register({
              required: true,
              minLength: 10,
            })}
            name="cardNumber"
          />
          <Typography color="primary" variant="h5">
            Fecha de vencimiento
          </Typography>
          <TextField
            label="Mes"
            style={{ width: "50%" }}
            helperText={errors.month ? "Campo requerido" : null}
            InputProps={{
              style: {
                marginBottom: 15,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <EventIcon style={{}} />
                </InputAdornment>
              ),
            }}
            defaultValue={1}
            error={Boolean(errors.month)}
            fullWidth
            inputRef={register({ required: true, min: 1, max: 12 })}
            name="month"
            type="number"
          />
          <TextField
            label="Año"
            style={{ width: "50%" }}
            helperText={errors.year ? "Campo requerido" : null}
            InputProps={{
              style: {
                marginBottom: 15,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <EventIcon style={{}} />
                </InputAdornment>
              ),
            }}
            defaultValue={2021}
            error={Boolean(errors.year)}
            fullWidth
            inputRef={register({ required: true, min: 2020, max: 2050 })}
            name="year"
            type="number"
          />
          <Typography color="primary" variant="h5">
            Código de Seguridad
          </Typography>

          <TextField
            label="CVC"
            style={{ width: "100%" }}
            helperText={errors.securityCode ? "Campo requerido" : null}
            InputProps={{
              style: {
                marginBottom: 15,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <VerifiedUserIcon color="secondary" />
                </InputAdornment>
              ),
            }}
            defaultValue={""}
            error={Boolean(errors.securityCode)}
            fullWidth
            inputRef={register({ required: true, min: 0, max: 9999 })}
            name="securityCode"
            type="number"
          />
          {sending && (
            <Grid item xs={12} sm={12}>
              <LinearProgress />
            </Grid>
          )}
          {!sending && (
            <Button
              type="submit"
              style={{ width: "100%" }}
              color="primary"
              variant="contained"
            >
              Realizar el pago
            </Button>
          )}

          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <Typography color="secondary" variant="p">
              Nota: al completar tu pedido recibirás un correo de confirmación
              con tu compra
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

const errorMessage = {
  http_code: 402,
  details: [
    {
      debug_message:
        "This charge has been declined due to the users suspicious behavior.",
      message: "Card declined. Please try with another card or payment method.",
      code:
        "conekta.errors.processing.charge.card_payment.suspicious_behaviour",
    },
  ],
  object: "error",
  type: "processing_error",
  log_id: "67bcf2116aaa7c001b2c6d48",
  data: {
    livemode: true,
    amount: 41040,
    currency: "MXN",
    amount_refunded: 0,
    customer_info: {
      email: "cose0268@gmail.com",
      phone: "9992256725",
      name: "Edilberto  Contreras sulu ",
      corporate: false,
      customer_id: "cus_2xYTTifHmjJdgivPX",
      object: "customer_info",
    },
    object: "order",
    id: "ord_2xYTTimqnUY7r7E7V",
    metadata: {},
    is_refundable: false,
    created_at: 1740435985,
    updated_at: 1740435985,
    line_items: {
      object: "list",
      has_more: false,
      total: 2,
      data: [
        {
          name: "Comisión OnLine",
          unit_price: 3040,
          quantity: 1,
          object: "line_item",
          id: "line_item_2xYTTimqnUY7r7E7T",
          parent_id: "ord_2xYTTimqnUY7r7E7V",
          metadata: {},
          antifraud_info: {},
        },
        {
          name: "6º Edición del Medio Maratón de Valladolid 2025",
          unit_price: 38000,
          quantity: 1,
          object: "line_item",
          id: "line_item_2xYTTimqnUY7r7E7S",
          parent_id: "ord_2xYTTimqnUY7r7E7V",
          metadata: {},
          antifraud_info: {},
        },
      ],
    },
    charges: {
      object: "list",
      has_more: false,
      total: 1,
      data: [
        {
          id: "67bcf2116aaa7c001b2c6d50",
          livemode: true,
          created_at: 1740435985,
          currency: "MXN",
          failure_code: "suspected_fraud",
          failure_message:
            "This charge has been declined due to the users suspicious behavior.",
          device_fingerprint: "p8qqP3Con8wj3ofXxF9p5Y4tajkELpEX",
          payment_method: {
            name: "Edilberto  Contreras sulu ",
            exp_month: "02",
            exp_year: "26",
            object: "card_payment",
            type: "debit",
            last4: "5273",
            brand: "mastercard",
            issuer: "santander",
            account_type: "CHEQUE ELECTRONICO DEBITO",
            country: "MX",
            fraud_indicators: [],
            antifraud_flag: "",
            three_ds_flow_required: false,
          },
          object: "charge",
          description: "Payment from order",
          status: "declined",
          amount: 41040,
          fee: 1624,
          customer_id: "cus_2xYTTifHmjJdgivPX",
          order_id: "ord_2xYTTimqnUY7r7E7V",
        },
      ],
    },
  },
};
