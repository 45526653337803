import * as types from "../actions/action-types";

const initialState = [];
export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case types.INIT_EVENTS:
      return Object.assign({}, state, {
        events: action.events,
      });
    case types.EVENT_DETAIL:
      return Object.assign({}, state, {
        eventDetail: action.eventDetail,
      });
    case types.EVENT_CONFIGURATION:
      return Object.assign({}, state, {
        eventConfiguration: action.eventConfiguration,
      });
    case types.EVENT_RESULTS:
      return Object.assign({}, state, {
        eventResults: action.eventResults,
        eventResultsOriginal: action.eventResultsOriginal,
      });
    case types.EVENT_POINTS_CONTROLS:
      return Object.assign({}, state, {
        puntosControl: action.puntosControl,
      });
    case types.EVENT_PHOTOS_CONFIGURATION:
      return Object.assign({}, state, {
        eventPhotoConfiguration: action.eventPhotoConfiguration,
      });
    case types.EVENT_CONVOCATORY:
      return Object.assign({}, state, {
        eventConvocatory: action.eventConvocatory,
      });
    case types.EVENT_SUBEVENTS:
      return Object.assign({}, state, {
        eventSubEvents: action.eventSubEvents,
        eventSubEventsSnap: action.eventSubEventsSnap,
      });
    case types.EVENT_SUBEVENTS_REGISTRY:
      return Object.assign({}, state, {
        eventSubEventsRegistry: action.eventSubEventsRegistry,
        eventSubEventsRegistrySnap: action.eventSubEventsRegistrySnap,
      });

    case types.EVENT_USERS:
      return Object.assign({}, state, {
        eventUsers: action.eventUsers,
      });
    case types.CUSTOM_STRINGS:
      return Object.assign({}, state, {
        customStrings: action.customStrings,
      });
    case types.CUSTOM_VIEWS:
      return Object.assign({}, state, {
        customViews: action.customViews,
      });

    default:
      return state;
  }
}
