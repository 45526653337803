import React from "react";
import { connect } from "react-redux";

import {
  configuracionEvento,
  resultadosEventoNew,
  detalleEvento,
  resultReportType,
  getPuntosControl,
  eventSubEvents,
} from "../../../data-store/actions/events-actions";
import ResultsReport from "../reports/ResultsReport";
import ResultsReportNew from "../reports/ResultsReportNew";

import { Link } from "react-router-dom";

import BuscadorResults from "../reports/BuscadorResults";
import FullScreenDialog from "../Modals/FullScreenDialog";
import FormDialog from "../Modals/FormDialog";
import { formatEventDate } from "../../../services/utils";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppContainer from "../../../AppContainer";
import RealTimeResults from "../reports/RealTimeResults";
import { Grid, Paper } from "@material-ui/core";
import TotalModRamCat from "./TotalModRamCat";
import LiveResumeCard from "./LiveResumeCard";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

////////////////////////  https://css-tricks.com/rendering-lists-using-react-virtualized/

// implementar esto para todas las listas.

class LiveEventShowView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, controles: false, reportType: 0 };
    //if (this.state.controles) {
    this.props.getPuntosControl();
    this.props.getEventSubEvents();

    //}
  }

  openResultsReport = (e, tipo) => {
    e.preventDefault();
    this.setState({
      reportType: tipo,
      isOpen: !this.state.isOpen,
    });
  };

  toggleResultsReport = () => {
    console.log("show Report");
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    var element;

    let BarButtons = [];
    BarButtons.push(
      <FormDialog
        key={1}
        buttomLabel={"search"}
        ShowIcon={true}
        ButtonStyle={null}
        maxWidthProp={"sm"}
        report={<BuscadorResults icon={false} />}
      />
    );
    let numberAndNameFinder = (
      <FormDialog
        key={1}
        buttomLabel={"Buscar por nombre ó Número"}
        ShowIcon={false}
        ButtonStyle={null}
        maxWidthProp={"sm"}
        style={{
          minHeight: "40vh",
        }}
        report={<BuscadorResults icon={false} />}
      />
    );

    let numberAndNameFinder2 = <BuscadorResults icon={false} />;

    let WinnersButton = (
      <FullScreenDialog
        buttomLabel={"Resultados Preliminares de Ganadores"}
        title={"Resultados Preliminares de Ganadores"}
        report={
          <ResultsReportNew
            reportResultType={4}
            controles={this.state.controles}
            puntosControl={this.props.puntosControl}
          />
        }
        BarButtons={BarButtons}
      />
    );
    let realTime = (
      <FullScreenDialog
        buttomLabel={"Tiempo Real"}
        title={"Tiempo Real"}
        report={
          <RealTimeResults
            eventDetail={this.props.eventDetail}
            eventResults={this.props.eventResults}
            puntosControl={this.props.puntosControl}
          />
        }
        BarButtons={BarButtons}
      />
    );
    let CategoryResults = (
      <FullScreenDialog
        buttomLabel={"Resultados Categoría"}
        title={"Resultados por Categoría"}
        report={
          <ResultsReportNew
            reportResultType={3}
            controles={this.state.controles}
            eventDetail={this.props.eventDetail}
            puntosControl={this.props.puntosControl}
          />
        }
        BarButtons={BarButtons}
      />
    );
    let GenderResults = (
      <FullScreenDialog
        buttomLabel={"Resultados Genero"}
        title={"Resultados por Genero"}
        report={
          <ResultsReportNew
            reportResultType={2}
            controles={this.state.controles}
            eventDetail={this.props.eventDetail}
            puntosControl={this.props.puntosControl}
          />
        }
        BarButtons={BarButtons}
      />
    );
    let GeneralResults = (
      <FullScreenDialog
        buttomLabel={"Resultados Generales"}
        title={"Resultados Generales"}
        report={
          <ResultsReportNew
            reportResultType={1}
            controles={this.state.controles}
            eventDetail={this.props.eventDetail}
            puntosControl={this.props.puntosControl}
          />
        }
        BarButtons={BarButtons}
      />
    );

    if (this.props.evento !== undefined) {
      element = (
        <Grid container direction="row">
          <Grid item xs={12} sm={12}>
            <Paper style={{ margin: 20 }}>
              <Grid container direction="row">
                <Grid item xs={12} sm={3}>
                  <CardMedia
                    component="img"
                    alt={"Logo evento:" + this.props.evento.nombreEvento}
                    height="auto"
                    image={this.props.evento.logoEvento}
                    title={"Logo evento:" + this.props.evento.nombreEvento}
                  />
                </Grid>
                <Grid item xs={12} sm={9}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {this.props.evento.nombreEvento} (PRELIMINARES)
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      EVENTO EN PROCESO
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {this.props.evento.lugarEvento},{" "}
                      {this.props.evento.ciudadEvento},{" "}
                      {this.props.evento.paisEvento}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {WinnersButton}
                    {numberAndNameFinder}
                  </CardActions>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            {this.props.eventDetail && this.props.eventResults && (
              <Paper
                style={{ padding: 10, margin: 10, backgroundColor: "#e0e0e0" }}
              >
                <Typography color="primary" variant={"h1"} align="center">
                  {
                    this.props.eventResults.filter((a) => a.to !== "00:00:00")
                      .length
                  }
                </Typography>
                <Typography variant={"h5"} align="center">
                  Finalistas de {this.props.eventResults.length} atletas
                  participando
                </Typography>
              </Paper>
            )}
          </Grid>

          <Grid item xs={12} sm={12}>
            {this.props.eventDetail &&
              this.props.eventResults &&
              this.props.puntosControl &&
              this.props.eventSubEvents && (
                <Paper>
                  <RealTimeResults
                    eventDetail={this.props.eventDetail}
                    eventResults={this.props.eventResults}
                    puntosControl={this.props.puntosControl}
                    hideClock={false}
                    numberOfRows={10}
                  />
                </Paper>
              )}
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            {this.props.eventResults && this.props.eventSubEvents && (
              <Paper>
                <TotalModRamCat
                  eventSubEvents={this.props.eventSubEvents}
                  eventResults={this.props.eventResults}
                />
              </Paper>
            )}
          </Grid> */}
        </Grid>
      );
    } else {
      element = <LinearProgress />;
    }

    return <AppContainer> {element}</AppContainer>;
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  return {
    eventDetailSend: dispatch(detalleEvento(match.params.EventoIndex)),
    eventResultsSend: dispatch(resultadosEventoNew(match.params.EventoIndex)),
    eventSubEventsSend: dispatch(eventSubEvents(match.params.EventoIndex)),

    eventConfigurationSend: dispatch(
      configuracionEvento(match.params.EventoIndex)
    ),
    getPuntosControl: () => {
      dispatch(getPuntosControl(match.params.EventoIndex));
    },
    getEventSubEvents: () => {
      dispatch(eventSubEvents(match.params.EventoIndex));
    },
  };
};

const mapStateToProps = (state, { match }) => {
  var EventoIndex = match.params.EventoIndex;
  var evento;
  if (state.events.events != undefined) {
    evento = state.events.events[EventoIndex];
  } else {
    evento = undefined;
  }
  return {
    evento: evento,
    eventDetail: state.events.eventDetail,
    eventResults: state.events.eventResults,
    eventConfiguration: state.events.eventConfiguration,
    puntosControl: state.events.puntosControl,
    eventSubEvents: state.events.eventSubEvents,
  };
};

const LiveEventShow = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveEventShowView);

export default LiveEventShow;
